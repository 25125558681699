<template>
  <v-row>
    <v-col>
      <report-list v-if="showDashboard" />

      <v-row v-else>
        <data-table
          ref="table"
          :actions="actions"
        >
          <template v-slot:empty-state>
            <div>Selected publisher has no Discrepancy data.</div>
          </template>
        </data-table>

        <creatives-discrepancy-modal />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapState } = createNamespacedHelpers('tools')

export default {
  name: 'Discrepancy',
  components: {
    DataTable: () => import('@/tools/components/table/DataTable'),
    CreativesDiscrepancyModal: () => import('../components/modals/CreativesDiscrepancyModal'),
    ReportList: () => import('@/dashboard/components/ReportList'),
  },
  data() {
    return {
      actions: [
        {
          event: 'delivery/openTabToLineItem',
          name: 'Go to Line Item',
        },
        {
          event: 'delivery/openTabToOrder',
          name: 'Go to Order',
        },
        {
          event: 'delivery/showCreativesDiscrepancyModal',
          name: 'Creatives Discrepancy',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'currentGroupByProperties',
    ]),
    ...mapState({
      selectedSearch: (state) => state.selectedSearch,
    }),
    showDashboard() {
      return this.selectedSearch?.dataType === 'custom'
    },
  },
}
</script>
